import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    meta: { title: 'kdo je viktorie' },
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/matematika',
    name: 'matematika',
    meta: { title: 'matematika' },
    component: () => import('../views/MatematikaView.vue')
  },
  {
    path: '/KISK',
    name: 'KISK',
    meta: { title: 'KISK' },
    component: () => import('../views/KiskView.vue')
  },
  {
    path: '/prvni-semestr',
    name: 'prvni-semestr',
    meta: { title: 'první semestr' },
    component: () => import('../views/Kisk-PrvniSemestr.vue')
  },
  {
    path: '/v-kurzu',
    name: 'v-kurzu',
    meta: { title: 'V Kurzu' },
    component: () => import('../views/Calderon.vue'),
    children: [
      {
        path: 'x',  // This empty path means it's the default child route
        name: 'v-kurzu-main',
        component: () => import('../views/CalderonMain.vue')
      },
      {
        path: 'D1',
        name: 'v-kurzuD1',
        meta: { title: 'Shrnutí D1' },
        component: () => import('../views/CalderonD1View.vue')
      },
      {
        path: 'D2',
        name: 'v-kurzu-D2',
        meta: { title: 'Shrnutí D2' },
        component: () => import('../views/CalderonD2View.vue')
      },
      {
        path: 'D3',
        name: 'v-kurzu-D3',
        meta: { title: 'Shrnutí D3' },
        component: () => import('../views/CalderonD3View.vue')
      },
      {
        path: 'D4',
        name: 'v-kurzu-D4',
        meta: { title: 'Shrnutí D4' },
        component: () => import('../views/CalderonD4View.vue')
      }
    ]
  },
  {
    path: '/zadani',
    name: 'v-kurzu-projekt',
    meta: { title: 'Zadání úkolů' },
    component: () => import('../views/CalderonUkoly.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue')
  }
  
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'viktoriino portfolio';
  next();
});

export default router